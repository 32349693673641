import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { Box } from 'pcln-design-system'
import useSeti from '@/hooks/useSeti'
import useBootstrapData from '@/hooks/useBootstrapData'
import analytics from '@/shared-utils/analytics'

const HomeDesktop = dynamic(() => import('./HomeDesktop'))
const HomeMobile = dynamic(() => import('./HomeMobile'))

export default function Home() {
  useSeti('AA_HOMEPAGE') // AA Test: Unconditional firing and no visual changes
  const { isMobile } = useBootstrapData()
  useEffect(() => {
    analytics.fireGA4Event({
      event: 'page_view',
      attributes: {
        page_name: 'homepage'
      }
    })
  }, [])
  return <Box>{isMobile ? <HomeMobile /> : <HomeDesktop />}</Box>
}
